<script>
import ApiService from "@/services/api.service";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      type: "Bank",
      form: {},
      property: {},
    };
  },
  computed: {
    ...mapGetters(["trust"]),

    trustId() {
      return this.$route.query.trust_id;
    },
    propertyId() {
      return this.$route.query.property_id;
    },
    amendmentId() {
      return this.$route.query.amendment_id;
    },
  },
  mounted() {
    this.getProperty();
  },
  methods: {
    getProperty() {
      ApiService.get(`immoveable-property/${this.propertyId}`).then(
        (response) => {
          this.property = response.data.immoveable_property ?? {};
          this.form = this.property;
        }
      );
    },
    updateDetails() {
      this.form._method = "PATCH";
      ApiService.post(`moveable-property/${this.propertyId}`, this.form).then(
        (response) => {
          this.$notify({
            group: "foo",
            title: "Aset telah berjaya dikemaskini.",
            duration: 5000,
            type: "success",
          });
        }
      );
    },
  },
};
</script>


<template>
  <div class="card-body">
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="form-group">
          <label for="home_phone_number">{{ $t("category") }}</label>
          <select
            name=""
            id=""
            class="form-control"
            v-model="form.category"
            :disabled="![0, 1, 2].includes(trust.status) || !amendmentId"
          >
            <option value="">- {{ $t("choose.category") }} -</option>
            <option value="Sebidang Tanah">{{ $t("piece-of-land") }}</option>
            <option value="Sebuah Bangunan">{{ $t("a-building") }}</option>
            <option value="Hakmilik Strata">{{ $t("strata-ownership") }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="home_phone_number">{{ $t("type-of-property") }}</label>
          <select
            name=""
            id=""
            class="form-control"
            v-model="form.property_type"
            :disabled="![0, 1, 2].includes(trust.status) || !amendmentId"
          >
            <option value="">- {{ $t("choose.category") }} -</option>
            <option value="Geran (Geran)">{{ $t("grant") }} (Geran)</option>
            <option value="Pajakan Negeri (PN)">{{ $t("state-lease") }} (PN)</option>
            <option value="Geran Mukim (GM)">{{ $t("subdistrict-grant") }} (GM)</option>
            <option value="Pajakan Mukim (PM)">{{ $t("subdistrict-lease") }} (PM)</option>
            <option value="Hakmilik Sementara Daerah (PSD)">
              {{ $t("district-temp-ownership") }} (PSD)
            </option>
            <option value="Hakmilik Sementara Mukim (PSM)">
              {{ $t("subdistrict-temp-ownership") }} (PSM)
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6 form-group">
        <label for="">{{ $t("property-no") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="form.property_no"
          :readonly="![0, 1, 2].includes(trust.status) || !amendmentId"
        />
      </div>
      <div class="col-md-6 form-group">
        <label for="">{{ $t("state") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="form.state"
          :readonly="![0, 1, 2].includes(trust.status) || !amendmentId"
        />
      </div>
      <div class="col-md-6 form-group">
        <label for="">{{ $t("district") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="form.district"
          :readonly="![0, 1, 2].includes(trust.status) || !amendmentId"
        />
      </div>
      <div class="col-md-6 form-group">
        <label for="">{{ $t("city") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="form.city"
          :readonly="![0, 1, 2].includes(trust.status) || !amendmentId"
        />
      </div>
      <div class="col-md-6 form-group">
        <label for="">{{ $t("lot-no") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="form.lot_number"
          :readonly="![0, 1, 2].includes(trust.status) || !amendmentId"
        />
      </div>
      <div class="col-md-12 form-group">
        <label for="">{{ $t("address") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="form.address"
          :readonly="![0, 1, 2].includes(trust.status) || !amendmentId"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button
          type="button"
          @click="updateDetails"
          class="btn btn-primary"
          v-if="[0, 1, 2].includes(trust.status) || amendmentId"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "home_phone_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("category")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.category,
      expression: "form.category"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "",
      "disabled": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "category", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("- " + _vm._s(_vm.$t("choose.category")) + " -")]), _c('option', {
    attrs: {
      "value": "Sebidang Tanah"
    }
  }, [_vm._v(_vm._s(_vm.$t("piece-of-land")))]), _c('option', {
    attrs: {
      "value": "Sebuah Bangunan"
    }
  }, [_vm._v(_vm._s(_vm.$t("a-building")))]), _c('option', {
    attrs: {
      "value": "Hakmilik Strata"
    }
  }, [_vm._v(_vm._s(_vm.$t("strata-ownership")))])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "home_phone_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("type-of-property")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.property_type,
      expression: "form.property_type"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "",
      "disabled": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "property_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("- " + _vm._s(_vm.$t("choose.category")) + " -")]), _c('option', {
    attrs: {
      "value": "Geran (Geran)"
    }
  }, [_vm._v(_vm._s(_vm.$t("grant")) + " (Geran)")]), _c('option', {
    attrs: {
      "value": "Pajakan Negeri (PN)"
    }
  }, [_vm._v(_vm._s(_vm.$t("state-lease")) + " (PN)")]), _c('option', {
    attrs: {
      "value": "Geran Mukim (GM)"
    }
  }, [_vm._v(_vm._s(_vm.$t("subdistrict-grant")) + " (GM)")]), _c('option', {
    attrs: {
      "value": "Pajakan Mukim (PM)"
    }
  }, [_vm._v(_vm._s(_vm.$t("subdistrict-lease")) + " (PM)")]), _c('option', {
    attrs: {
      "value": "Hakmilik Sementara Daerah (PSD)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("district-temp-ownership")) + " (PSD) ")]), _c('option', {
    attrs: {
      "value": "Hakmilik Sementara Mukim (PSM)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("subdistrict-temp-ownership")) + " (PSM) ")])])])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("property-no")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.property_no,
      expression: "form.property_no"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.property_no
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "property_no", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("district")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.district,
      expression: "form.district"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.district
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "district", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("lot-no")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.lot_number,
      expression: "form.lot_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.lot_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "lot_number", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-12 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.address,
      expression: "form.address"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "address", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-right"
  }, [[0, 1, 2].includes(_vm.trust.status) || _vm.amendmentId ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.updateDetails
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }